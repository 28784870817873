import { Timestamp } from "..";

export interface PortalPendingInvite extends InviteChild {
  id: string;
  email: string;
}
export interface CreatePortalParentInviteArgs {
  organisationId: string;
  inviterId: string;
  inviterName: string;
  parent: InviteParent;
  language: "en" | "fr";
  children: InviteChildArgs[];
}

export interface EditPortalParentInviteArgs {
  parent: InviteParent;
  language: "en" | "fr";
}

export interface PortalParentInvite extends CreatePortalParentInviteArgs {
  id: string;
  parent: InviteParent;
  children: InviteChild[];
  sentAt?: Timestamp;
  status?: "pending" | "accepted" | "declined";
}

export interface InviteChildArgs {
  professionalIds: string[];
  firstName: string;
  lastName: string;
  sex: "male" | "female" | "preferNotToAnswer";
  birthDate: number;
  targetedSkills: string[];
}

export interface InviteChild extends InviteChildArgs {
  groupId: string;
}

export interface InviteParent {
  firstName: string;
  lastName: string;
  email: string;
}

export enum ParentInviteErrorCode {
  EmailAlreadyTaken = "invite/email-already-taken",
  EmailAlreadyInvited = "invite/email-already-invited",
}
