export enum Partners {
  GREEN_SHIELD = "green-shield",
  GREEN_SHIELD_LUCKY_20 = "green-shield-lucky-20",
}

export interface PartnersInfo {
  partner: Partners;
  policyNumber?: string;
  groupNumber?: string;
}

export interface GreenShieldPartnerInfo extends PartnersInfo {
  partner: Partners.GREEN_SHIELD;
  groupNumber: string;
  policyNumber: string;
}
