import auth from "./auth.json";
import coach from "./coach.json";
import dashboard from "./dashboard.json";
import ftue from "./ftue.json";
import general from "./general.json";
import journal from "./journal.json";
import landingPages from "./landingPages.json";
import medication from "./medication.json";
import navigation from "./navigation.json";
import portal from "./portal.json";
import portalResources from "./portalResources.json"
import routine from "./routine.json";
import settings from "./settings.json";
import templates from "./templates.json";
import validation from "./validation.json";
import webappAuthentication from "./webappAuthentication.json";
import webappBase from "./webappBase.json";
import webappBilling from "./webappBilling.json";
import webappDashboard from "./webappDashboard.json";
import webappLanding from "./webappLanding.json";
import webappSettings from "./webappSettings.json";

export {
  auth,
  coach,
  dashboard,
  ftue,
  general,
  journal,
  landingPages,
  medication,
  navigation,
  portal,
  portalResources,
  routine,
  settings,
  templates,
  validation,
  webappAuthentication,
  webappBase,
  webappBilling,
  webappDashboard,
  webappLanding,
  webappSettings
}